import CONSTANTS from '../../../Common/constants'

const MENU = [
  // {
  //   name: 'Load balancer',
  //   link: '#balancer',
  //   className: 'gtm-load-balancer-button',
  // },
  {
    name: 'Networks',
    link: '#networks',
  },
  {
    name: 'Plans',
    link: '#plans',
  },
  {
    name: 'Solana',
    link: '/solana',
  },
  // {
  //   name: 'Roadmap',
  //   link: '#roadmap',
  // },
  {
    name: 'Partners',
    link: '#partners',
  },
  {
    name: 'Contact us',
    link: '#contact',
  },
  {
    name: 'FAQ',
    link: '#faq',
  },
  {
    name: 'Sign In',
    link: CONSTANTS.signup_url,
  },
]

export default MENU
